<template>
  <div class="Landing__container">
    <Support />
    <LandingNavbar />
    <slot />
    <LandingFooter />
  </div>
</template>
<script setup lang="ts">
useHead({
  htmlAttrs: {
    lang: "en",
  },
  meta: [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
  ],
});
</script>
<style lang="scss" scoped>
body {
  margin: 0;
}
.Landing__container {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <nav class="Navbar__container">
    <div class="Navbar__wrapper" :class="navbarScrollClass">
      <div class="Navbar__content-wrapper">
        <div class="Navbar__logo-wrapper">
          <NuxtLink to="/">
            <IconKingsConference />
          </NuxtLink>
        </div>
        <div class="Navbar__mobile-buttons">
          <ClientOnly>
            <button
              ref="currencyButtonMobile"
              aria-label="Currency change button"
              class="Navbar__currency-button mobile"
              @click="handleCurrencyDropdownToggle"
            >
              <LandingIconCurrencyExchange />
            </button>
            <div
              v-on-click-outside="onClickOutsideHandler"
              :class="{ open: currencyDropdownOpen }"
              class="Navbar__currency-dropdown"
            >
              <ul class="Navbar__currency-list">
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('ESP')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="circle-multiple-outline"
                    />Espees
                    <o-icon
                      v-if="chosenCurrencyCode === 'ESP'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('NGN')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-ngn"
                    />Naira
                    <o-icon
                      v-if="chosenCurrencyCode === 'NGN'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('GBP')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-gbp"
                    />Pound
                    <o-icon
                      v-if="chosenCurrencyCode === 'GBP'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('USD')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-usd"
                    />U.S. Dollar
                    <o-icon
                      v-if="chosenCurrencyCode === 'USD'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </ClientOnly>
          <button
            class="Navbar__burger-button"
            aria-label="Menu button"
            @click="handleMobileMenuToggle"
          >
            <span class="first" :class="{ open: mobileMenuOpen }"></span>
            <span class="second" :class="{ open: mobileMenuOpen }"></span>
            <span class="third" :class="{ open: mobileMenuOpen }"></span>
          </button>
        </div>
        <div class="Navbar__desktop-wrapper">
          <ul class="Navbar__desktop-links">
            <li class="Navbar__desktop-link">
              <NuxtLink to="#about"> About </NuxtLink>
            </li>
            <li class="Navbar__desktop-link">
              <NuxtLink to="#features"> Features </NuxtLink>
            </li>
            <li class="Navbar__desktop-link">
              <NuxtLink to="#rooms"> Rooms </NuxtLink>
            </li>
            <li class="Navbar__desktop-link">
              <NuxtLink to="#plans"> Plans </NuxtLink>
            </li>
            <li class="Navbar__desktop-link">
              <NuxtLink to="#faq"> FAQ's </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="Navbar__desktop-buttons">
          <ClientOnly>
            <button
              ref="currencyButtonDesktop"
              class="Navbar__currency-button"
              aria-label="Currency change button"
              @click="handleCurrencyDropdownToggle"
            >
              <o-icon
                class="Navbar__currency-icon"
                :icon="chosenCurrency?.icon"
              />
              {{ chosenCurrency?.name }}
              <o-icon
                class="Navbar__currency-menu-icon"
                :class="{ open: currencyDropdownOpen }"
                icon="menu-down"
              />
            </button>
            <div
              v-on-click-outside="onClickOutsideHandler"
              :class="{ open: currencyDropdownOpen }"
              class="Navbar__currency-dropdown"
            >
              <ul class="Navbar__currency-list">
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('ESP')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="circle-multiple-outline"
                    />Espees
                    <o-icon
                      v-if="chosenCurrencyCode === 'ESP'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('NGN')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-ngn"
                    />Naira
                    <o-icon
                      v-if="chosenCurrencyCode === 'NGN'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('GBP')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-gbp"
                    />Pound
                    <o-icon
                      v-if="chosenCurrencyCode === 'GBP'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
                <li>
                  <button
                    class="Navbar__currency-list-button"
                    @click.stop="handleChangeCurrency('USD')"
                  >
                    <o-icon
                      class="Navbar__currency-icon"
                      icon="currency-usd"
                    />U.S. Dollar
                    <o-icon
                      v-if="chosenCurrencyCode === 'USD'"
                      class="Navbar__currency-checkmark"
                      icon="check"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </ClientOnly>
          <NuxtLink
            to="/dashboard"
            :external="true"
            class="Navbar__button-login"
            >Join for free</NuxtLink
          >
        </div>
      </div>
    </div>
    <div v-if="mobileMenuOpen" class="Navbar__mobile-wrapper">
      <ul class="Navbar__mobile-links">
        <li class="Navbar__mobile-link" @click="handleMobileMenuClose">
          <NuxtLink to="#about"> About </NuxtLink>
        </li>
        <li class="Navbar__mobile-link" @click="handleMobileMenuClose">
          <NuxtLink to="#features"> Features </NuxtLink>
        </li>
        <li class="Navbar__mobile-link" @click="handleMobileMenuClose">
          <NuxtLink to="#rooms"> Rooms </NuxtLink>
        </li>
        <li class="Navbar__mobile-link" @click="handleMobileMenuClose">
          <NuxtLink to="#plans"> Plans </NuxtLink>
        </li>
        <li class="Navbar__mobile-link" @click="handleMobileMenuClose">
          <NuxtLink to="#faq"> FAQ's </NuxtLink>
        </li>
      </ul>
      <NuxtLink
        class="Navbar__button-login w-250"
        to="/dashboard"
        :external="true"
        ><IconKingschat /> Login with KingsChat</NuxtLink
      >
    </div>
  </nav>
</template>
<script setup lang="ts">
import {
  OnClickOutsideOptions,
  useWindowScroll,
  watchThrottled,
} from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import { useCurrencyStore } from "~/store/currency";

const mobileMenuOpen = ref(false);
const currencyDropdownOpen = ref(false);
const searchParamsState = useUrlSearchParams("history");
const currency = useCurrencyStore();

const currencyButtonMobile = ref(null);
const currencyButtonDesktop = ref(null);

const onClickOutsideHandler: [() => void, OnClickOutsideOptions] = [
  () => {
    currencyDropdownOpen.value = false;
  },
  { ignore: [currencyButtonMobile, currencyButtonDesktop] },
];

const chosenCurrencyCode = computed(() => {
  if (
    searchParamsState.currency &&
    ["NGN", "USD", "GBP"].includes(`${searchParamsState.currency}`)
  ) {
    return searchParamsState.currency;
  }
  return currency.defaultCurrency.currency.code;
});

const chosenCurrency = computed(() => {
  switch (chosenCurrencyCode.value) {
    case "NGN":
      return { name: "Naira", icon: "currency-ngn" };
    case "USD":
      return { name: "U.S. Dollar", icon: "currency-usd" };
    case "GBP":
      return { name: "Pound", icon: "currency-gbp" };
    case "ESP":
      return { name: "Espees", icon: "circle-multiple-outline" };
  }
});

const handleChangeCurrency = (code: "NGN" | "USD" | "GBP" | "ESP") => {
  searchParamsState.currency = code;
  const curr = currency.currencies.find((item) => item.code === code);
  if (curr) {
    currency.setDefaultCurrency(curr);
  }
  handleCurrencyDropdownClose();
};

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (mobileMenuOpen.value) {
        return "body-scroll-lock";
      }
      return "";
    }),
  },
});
const { y } = useWindowScroll();

const navbarScrollClass = ref("");

watchThrottled(
  () => y.value,
  (newValue) => {
    if (newValue > 30) {
      navbarScrollClass.value = "scrolled";
      return;
    }
    navbarScrollClass.value = "";
  },
  { throttle: 1000 },
);

const handleMobileMenuOpen = () => {
  mobileMenuOpen.value = true;
};
const handleMobileMenuClose = () => {
  mobileMenuOpen.value = false;
};
const handleCurrencyDropdownToggle = () => {
  currencyDropdownOpen.value = !currencyDropdownOpen.value;
};

const handleCurrencyDropdownClose = () => {
  currencyDropdownOpen.value = false;
};

const handleMobileMenuToggle = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value;
};
</script>
<style scoped lang="scss">
.Navbar {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 9;
  }
  &__wrapper {
    padding: 16px;
    background: $color-basic-white;
    z-index: 2;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 16px 0px rgba(34, 41, 52, 0.06);
      opacity: 0;
      transition: opacity 0.4s ease-in;
    }
    &.scrolled {
      &::after {
        opacity: 1;
      }
    }
    @include custom-min-width(992px) {
      padding: 20px;
    }
    @include lg {
      padding: 20px 80px;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    @include custom-min-width(992px) {
      max-width: 1440px;
      width: 100%;
      margin: auto;
    }
  }
  &__mobile-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    @include custom-min-width(992px) {
      display: none;
    }
  }
  &__mobile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 80px 0 40px 0;
    justify-content: space-evenly;
    background: $color-basic-white;
    @include sm {
      padding: 120px 0 80px 0;
    }
    @include custom-min-width(992px) {
      display: none;
    }
  }
  &__mobile-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    row-gap: 24px;
    @include sm {
      row-gap: 40px;
    }
  }
  &__mobile-link {
    a {
      @include Header-24;
      color: $color-neutral-950;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__desktop-wrapper {
    display: none;
    @include custom-min-width(992px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  &__desktop-links {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0;
    @include lg {
      column-gap: 50px;
    }
  }
  &__desktop-link {
    @include Label-16;
    a {
      color: $color-neutral-950;
      text-decoration: none;
      transition: color 0.4s ease-in;
      &:hover {
        color: $brand-primary-500;
      }
    }
  }
  &__desktop-buttons {
    display: none;
    position: relative;
    @include custom-min-width(992px) {
      display: flex;
      flex-direction: row;
      column-gap: 16px;
      align-items: center;
    }
  }
  &__currency-dropdown {
    display: none;
    position: absolute;
    bottom: -265px;
    right: 50px;
    padding: 24px 16px;
    background: $color-basic-white;
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    @include custom-min-width(992px) {
      right: unset;
      left: 0;
    }
    &.open {
      display: flex;
    }
  }
  &__burger-button {
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 999px;
    background: $color-neutral-100;
    position: relative;
    border: none;
    display: flex;
    cursor: pointer;
    .first {
      width: 18px;
      height: 2px;
      background: $brand-primary-500;
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.4s ease-in;
      &.open {
        transform: translateX(-50%) translateY(5px) rotate(45deg);
      }
    }
    .second {
      width: 18px;
      height: 2px;
      background: $brand-primary-500;
      position: absolute;
      top: 21px;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.4s ease-in;
      &.open {
        transform: translateX(-50%) scaleX(0);
      }
    }
    .third {
      width: 18px;
      height: 2px;
      background: $brand-primary-500;
      position: absolute;
      top: 26px;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.4s ease-in;
      &.open {
        transform: translateX(-50%) translateY(-5px) rotate(-45deg);
      }
    }
  }
  &__currency-button {
    @include Label-16;
    color: $color-element-dark-strong;
    background: none;
    border: none;
    padding: 10px 18px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    text-wrap: nowrap;
    cursor: pointer;
    min-width: 170px;
    &.mobile {
      min-width: initial;
      padding: 10px;
      width: 44px;
      height: 44px;
      border-radius: 999px;
      background: $color-neutral-100;
    }
  }
  &__currency-icon {
    color: $color-element-dark-disabled;
  }
  &__currency-checkmark {
    color: $color-basic-success-500;
    margin-left: auto;
  }
  &__currency-menu-icon {
    transition: transform 0.4s ease-in;
    margin-left: auto;
    &.open {
      transform: rotate(-180deg);
    }
  }
  &__currency-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__currency-list-button {
    background: none;
    border: none;
    padding: 8px;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    cursor: pointer;
    min-width: 160px;
  }
  &__button-login {
    @include button-primary;
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    &.disabled {
      display: none;
      pointer-events: none;
      background: $color-neutral-200;
      color: $color-element-dark-disabled;
    }
    &.w-250 {
      width: 250px;
    }
  }
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 23C17.7 23 22.39 18.66 22.95 13.1C23.01 12.51 22.54 12 21.95 12C21.44 12 21.01 12.38 20.96 12.88C20.52 17.44 16.67 21 12 21C8.88 21 6.13 19.41 4.52 17H6C6.55 17 7 16.55 7 16C7 15.45 6.55 15 6 15H2C1.45 15 1 15.45 1 16V20C1 20.55 1.45 21 2 21C2.55 21 3 20.55 3 20V18.33C4.99 21.15 8.28 23 12 23ZM12 1C6.3 1 1.61 5.34 1.05 10.9C1 11.49 1.46 12 2.05 12C2.56 12 2.99 11.62 3.04 11.12C3.48 6.56 7.33 3 12 3C15.12 3 17.87 4.59 19.48 7H18C17.45 7 17 7.45 17 8C17 8.55 17.45 9 18 9H22C22.55 9 23 8.55 23 8V4C23 3.45 22.55 3 22 3C21.45 3 21 3.45 21 4V5.67C19.01 2.85 15.72 1 12 1ZM11.12 5.88C11.12 5.39 11.52 5 12 5C12.48 5 12.88 5.39 12.88 5.88V6.25C13.95 6.44 14.63 7.01 15.04 7.55C15.38 7.99 15.2 8.63 14.68 8.85C14.32 9 13.9 8.88 13.66 8.57C13.38 8.19 12.88 7.8 12.06 7.8C11.36 7.8 10.25 8.17 10.25 9.19C10.25 10.14 11.11 10.5 12.89 11.09C15.29 11.92 15.9 13.14 15.9 14.54C15.9 17.16 13.4 17.67 12.88 17.76V18.13C12.88 18.61 12.49 19.01 12 19.01C11.51 19.01 11.12 18.62 11.12 18.13V17.71C10.49 17.56 9.19 17.1 8.43 15.61C8.2 15.17 8.46 14.59 8.92 14.41C9.33 14.25 9.82 14.4 10.03 14.79C10.35 15.4 10.98 16.16 12.15 16.16C13.08 16.16 14.13 15.68 14.13 14.55C14.13 13.59 13.43 13.09 11.85 12.52C10.75 12.13 8.5 11.49 8.5 9.21C8.5 9.11 8.51 6.81 11.12 6.25V5.88Z"
      fill="#25272F"
    />
  </svg>
</template>
